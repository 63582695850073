<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("SALES_PAYMENTS.SALES_PAYMENTS_LIST") }}
            </h3>
          </div>

          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openSalesPaymentCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_SALES_PAYMENTS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("SALES_PAYMENTS.ADD_SALES_PAYMENT") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_SALES_PAYMENTS)"
              :objectType="'SalesPayments'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <sales-payment-list-table
        @onViewSalesPayment="openSalesPaymentViewModal"
        @onEditSalesPayment="openSalesPaymentEditModal"
        @onDeleteSalesPayment="deleteSalesPayment"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewSalesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewSalesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_PAYMENT_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SALES_PAYMENTS.VIEW_SALES_PAYMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openSalesPayment"
                :objectType="'SalesPayments'"
                :objectId="openSalesPayment.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closeSalesPaymentModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-sales-payment-page
              @onValidateSalesPayment="validateSalesPayment"
              @onCancelSalesPayment="cancelSalesPayment"
              v-if="openSalesPayment"
              :salesPaymentId="openSalesPayment.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditSalesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditSalesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_PAYMENT'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SALES_PAYMENTS.EDIT_SALES_PAYMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openSalesPaymentViewModal(openSalesPayment)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeSalesPaymentModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <edit-sales-payment-page
              v-if="openSalesPayment"
              :salesPaymentId="openSalesPayment.id"
              @onViewSalesPayment="openSalesPaymentViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddSalesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddSalesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SALES_PAYMENT'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("SALES_PAYMENTS.ADD_SALES_PAYMENT") }}
            </h1>
            <button class="close" @click="closeSalesPaymentModal">
              <i class="fal fa-times"></i>
            </button>
          </div>

          <div class="resizable-wrapper-content-body">
            <add-sales-payment-page
              @onViewSalesPayment="openSalesPaymentViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  PAYMENT_STATUS_DRAFT,
  PAYMENT_STATUS_COMPLETED,
} from "@/constants/payments";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SalesPaymentListTable from "./partials/SalesPaymentListTable.vue";
import EditSalesPaymentPage from "./components/EditSalesPaymentComponent.vue";
import AddSalesPaymentPage from "./components/AddSalesPaymentComponent.vue";
import ViewSalesPaymentPage from "./components/ViewSalesPaymentComponent.vue";
import Vue from "vue";
import PaymentValidationForm from "@/components/PaymentValidationForm.vue";
import PaymentCancelationForm from "@/components/PaymentCancelationForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SalesPaymentListTable,
    NotificationSubscription,
    EditSalesPaymentPage,
    AddSalesPaymentPage,
    ViewSalesPaymentPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewSalesPaymentModalOpened = false;
    let isEditSalesPaymentModalOpened = false;
    let isAddSalesPaymentModalOpened = false;
    let openSalesPayment = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewSalesPaymentModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditSalesPaymentModalOpened = true;
      }
      openSalesPayment = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddSalesPaymentModalOpened = true;
    }
    return {
      isViewSalesPaymentModalOpened: isViewSalesPaymentModalOpened,
      isEditSalesPaymentModalOpened: isEditSalesPaymentModalOpened,
      isAddSalesPaymentModalOpened: isAddSalesPaymentModalOpened,
      openSalesPayment: openSalesPayment,
      renderKey: 1,
      PAYMENT_STATUS_DRAFT: PAYMENT_STATUS_DRAFT,
      PAYMENT_STATUS_COMPLETED: PAYMENT_STATUS_COMPLETED,
    };
  },

  methods: {
    openSalesPaymentCreateModal() {
      this.closeSalesPaymentModal();
      this.isAddSalesPaymentModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesPayments",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openSalesPaymentViewModal(salesPayment, reRender = false) {
      this.closeSalesPaymentModal();
      this.openSalesPayment = salesPayment;
      this.isViewSalesPaymentModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesPayments",
          query: { id: this.openSalesPayment.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openSalesPaymentEditModal(salesPayment) {
      this.closeSalesPaymentModal();
      this.openSalesPayment = salesPayment;
      this.isEditSalesPaymentModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesPayments",
          query: { id: this.openSalesPayment.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeSalesPaymentModal() {
      this.isAddSalesPaymentModalOpened = false;
      this.isViewSalesPaymentModalOpened = false;
      this.isEditSalesPaymentModalOpened = false;
      this.openSalesPayment = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesPayments",
          query: {},
        }).href
      );
    },
    
    async cancelSalesPayment(salesPayment) {
      if (salesPayment.status == this.PAYMENT_STATUS_COMPLETED) {
        this.cancelValidatedSalesPayment(salesPayment);
        return;
      } else {
        this.loading = true;
        const confirmation = await swal.fire({
          text: this.$t("COMMON.CANCEL_PAYMENT_QUESTION"),
          type: "question",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonText: this.$t("COMMON.YES"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });

        try {
          if (confirmation.value === true) {
            await this.$store.dispatch(
              "salesPayments/cancel",
              salesPayment
            );
            this.renderKey++;
            this.$notify({
              type: "success",
              message: this.$t("COMMON.PAYMENT_CANCELED"),
            });
          }
          this.loading = false;
        } catch (error) {
          await this.showRequestError(error);
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      }
    },
    async cancelValidatedSalesPayment(salesPayment) {
      this.loading = true;
      const PaymentCancelationFormConstructor = Vue.extend(
        PaymentCancelationForm
      );
      const instance = new PaymentCancelationFormConstructor({
        propsData: {
          defaultPaymentSource: salesPayment.source,
          locationId: salesPayment.location_id,
          paymentMethodId: salesPayment.paymentMethod?.id,
        },
        i18n: this.$i18n,
        store: this.$store
      });
      
      // Monter le composant et obtenir l'élément HTML
      const html = instance.$mount().$el;
      const { value: formValues } = await swal.fire({
        title: this.$t("COMMON.CANCEL_PAYMENT_QUESTION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
        html: html,
        focusConfirm: true,
        preConfirm: () => {
          // Récupère les données du formulaire
          const source = document.querySelector(
            'input[name="payment_source"]:checked'
          )?.value;
          let pmId = null;
          if (!source) {
            swal.showValidationMessage(this.$t("PAYMENTS.SELECT_SOURCE"));
            return null;
          }
          if (source == "DPA") {
            pmId = document.getElementById("payment_method_id").value;
            if (!pmId) {
              swal.showValidationMessage(
                this.$t("PAYMENTS.SELECT_SOURCE_ACCOUNT")
              );
              return null;
            }
          }
          return {
            source,
            pmId,
          };
        },
      });
      try {
        if (formValues) {
          salesPayment.refund_source = formValues.source;
          salesPayment.refund_payment_methodId = formValues.pmId;
          await this.$store.dispatch(
            "salesPayments/cancel",
            salesPayment
          );
          this.renderKey++;
            this.$notify({
              type: "success",
              message: this.$t("COMMON.PAYMENT_CANCELED"),
            });
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async validateSalesPayment(salesPayment) {
      this.loading = true;

      const PaymentFormConstructor = Vue.extend(PaymentValidationForm);
      const instance = new PaymentFormConstructor({
        propsData: {
          defaultPaymentSource: salesPayment.source,
        },
        i18n: this.$i18n,
      });

      // Monter le composant et obtenir l'élément HTML
      const html = instance.$mount().$el;

      const { value:formValues } = await swal.fire({
        title: this.$t("COMMON.VALIDATE_PAYMENT_QUESTION"),
        type: "question",
        customClass: {
          popup: "warning-confirm",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",

        html: html,
        focusConfirm: true,
        preConfirm: () => {
          // Récupère les données du formulaire
          const source = document.querySelector(
            'input[name="payment_source"]:checked'
          )?.value;
          const note = document.getElementById(
            "payment-source-note"
          ).value;

          if (!source) {
            swal.showValidationMessage(this.$t("PAYMENTS.SELECT_SOURCE"));
            return null;
          }

          return {
            source,
            note,
          };
        },
      });
      try {
        if (formValues) {
          salesPayment.note = formValues.note; 
          salesPayment.source = formValues.source; 
          await this.$store.dispatch("salesPayments/validate", salesPayment);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("COMMON.PAYMENT_VALIDATED"),
          });
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async deleteSalesPayment(salesPayment) {
      const confirmation = await swal.fire({
        text: this.$t("SALES_PAYMENTS.DELETE_THIS_SALES_PAYMENT"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("salesPayments/destroy", salesPayment.id);
          this.renderKey++;
          this.closeSalesPaymentModal();
          this.$notify({
            type: "success",
            message: this.$t("SALES_PAYMENTS.SALES_PAYMENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
