<template>
    <badge :type="badgeType">
      {{ $t("PAYMENT_METHODS." + status) }}
    </badge>
  </template>
  
  <script>
  import {
    STATUS_AWAITING_VALIDATION,
    STATUS_INVALIDATED,
    STATUS_AWAITING_SIGNATURE,
    STATUS_SIGNATURE_DELINED,
    STATUS_PENDING,
    STATUS_ACTIVE,
    STATUS_DISABLED,
  } from "@/constants/paymentMethods";
  
  export default {
    name: "StatusBadge",
    props: {
      status: {
        type: String,
        required: true,
      },
    },
    computed: {
      badgeType() {
        switch (this.status) {
          case STATUS_DISABLED:
            return "secondary";
          case STATUS_ACTIVE:
            return "success";
          case STATUS_SIGNATURE_DELINED:
          case STATUS_INVALIDATED:
            return "danger";
          case STATUS_PENDING:
          case STATUS_AWAITING_SIGNATURE:
          case STATUS_AWAITING_VALIDATION:
            return "warning";
          default:
            return "default";
        }
      },
    },
  };
  </script>
  