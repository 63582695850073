<template>
  <el-select
    v-model="organizationModel"
    @change="organizationChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.ORGANIZATION')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getOrganizations"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="$t('COMMON.ALL_ORGANIZATIONS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="organization in organizations"
      :key="organization.id"
      :value="organization.id"
      :label="`${organization.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "organization-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    isBuyer: {
      type: Boolean,
      default: false,
      description: "Is buyer",
    },
    isSeller: {
      type: Boolean,
      default: false,
      description: "Is seller",
    },
    organization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    buyer: {
      type: String,
      default: null,
      description: "Buyer id",
    },
    seller: {
      type: String,
      default: null,
      description: "Seller id",
    },
    organizationsIds: {
      type: Array,
      default: () => [],
      description: "Organizations ids",
    },
    excludeIds: {
      type: Array,
      default: () => null,
      description: "Organizations ids to exclude",
    },
  },

  data() {
    return {
      organizationModel: this.organization,
      organizations: {},
    };
  },

  setup() {},

  created() {
    if (this.organization) {
      this.organizationsIds.push(this.organization);
    }
    this.getOrganizations(null, this.organizationsIds);
  },

  methods: {
    async getOrganizations(query = null, ids = null) {
      console.log("exclude", this.excludeIds);

      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(ids?.length ? { ids: ids } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.filterReseller,
            },
          };
        }

        if (this.excludeIds) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              exclude_ids: this.excludeIds,
            },
          };
        }

        await this.$store.dispatch("organizations/list", params);
        const organizationsArr = await this.$store.getters[
          "organizations/list"
        ];
        this.organizations = {};
        organizationsArr.forEach((organization) => {
          this.organizations[organization.id] = organization;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    organizationChanged(organizationId) {
      const organization = Object.values(this.organizations).find(
        (item) => item.id === organizationId
      );
      this.$emit("organizationChanged", organizationId, organization);
    },
  },

  watch: {
    organization(organization) {
      if (organization) {
        this.organizationModel = organization;
        if (organization !== this.organizationModel) {
          this.getOrganizations(null, organization);
        }
      } else {
        this.organizationModel = null;
      }
    },
    filterReseller(filterReseller) {
      this.getOrganizations();
    },
  },
};
</script>
