<template>
  <div class="payment-method-component">
    <div class="local-pm-details mt-3">
      <div class="payment-method-details mt-3">
        <!-- <h4>
          <span class="text-danger">{{ $t("PAYMENT_METHODS.DETAILS") }}</span>
        </h4> -->
        <object-details :data="getDetails(paymentMethod.details)" />
      </div>
      <div class="mt-3 mb-3 row-details w-full" v-if="paymentMethod?.specimen">
        <div class="row-wrapper fl_col gp10">
          <h5 class="p18 w-700 red-txt">
            {{ $t("PAYMENT_METHODS.CHECK_SPECIMEN") }}
          </h5>
          <el-image :src="paymentMethod?.specimen">
            <template #placeholder>
              <div class="image-slot">Loading<span class="dot">...</span></div>
            </template>
          </el-image>
        </div>
      </div>
    </div>
    <!-- <el-card class="payment-card">
        <div class="d-flex align-items-center">
          <div class="col-md-6 d-flex align-items-center">
            <div class="details ms-3">
              <p class="bank-name">{{ paymentMethod.details.bank_name }}</p>
              <p>{{ paymentMethod.details.account_number }}</p>
              <p>
                {{ $t("COMMON.CREATED_AT") }} : 
                {{ $timeZoneDateFormat(paymentMethod.created_at) }}
              </p>
            </div>
          </div>
        </div>
      </el-card> -->
  </div>
</template>

<script>
import { Card, Tag, Radio, Button } from "element-ui";
import StatusBadge from "./StatusBadge.vue";
import { STATUS_ACTIVE } from "@/constants/paymentMethods";
import ObjectDetails from "./ObjectDetails.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tag.name]: Tag,
    [Card.name]: Card,
    [Radio.name]: Radio,
    [Button.name]: Button,
    ObjectDetails
  },

  props: {
    paymentMethod: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      STATUS_ACTIVE: STATUS_ACTIVE,
    };
  },

  methods: {
    getDetails(details) {
      return {
        bank_name: details.bank_name,
        account_number: details.account_number,
        PAYMENT_METHOD_HOLDER: details.holder,
      };
    },
  },
  computed: {
    // Vérifie si ce moyen de paiement est marqué comme "par défaut"
    isDefault() {
      return this.paymentMethod.is_default;
    },
  },
};
</script>
