<template>
  <div>
    <h3 class="text-warning"> <i class="fa fa-exclamation-triangle"></i> {{$t("PAYMENTS.REFUND_WILL_BE_GENERATED")}}</h3>
    <div class="refund-payloads">
    <base-input
    class="mb-2"
        :label="$t('PAYMENTS.REFUND_PAYMENT_SOURCE')"
      >
    <el-radio-group v-model="paymentSource">
      <el-radio v-for="(label, val) in paymentSources" :key="val" name="payment_source" :label="val">{{ $t('PAYMENTS.'+label) }}</el-radio>
    </el-radio-group>
  </base-input>
    <base-input
      v-if="paymentSource=='DPA'"
      :label="$t('PAYMENTS.SELECT_PAYMENT_ACCOUNT')"
      >
        <payment-method-selector
          :paymentMethod="defaultPaymentSource == 'DPA' ? paymentMethodId?.toString() : null"
          :filterable="true"
          :showAll="false"
          :allowCreate="false"
          :filterLocation="locationId?.toString()"
          @paymentMethodChanged="paymentMethodSelected"
        />
        <input type="hidden" v-model="selectedPaymentMethodId" id="payment_method_id" name="payment_method_id" />
      </base-input>
    </div>
  </div>
</template>

<script>
import { RadioGroup, Radio } from "element-ui";
import PaymentMethodSelector from "@/views/Pages/AdminModule/PaymentMethodManagement/components/PaymentMethodSelector.vue";
import { paymentSourcesOptions } from "@/constants/payments";
export default {
  name: "PaymentCancelationForm",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    PaymentMethodSelector
  },

  props: {
    defaultPaymentSource: {
      type: String, 
      default: null, 
    },
    paymentMethodId: {
      type: String, 
      default: null, 
    },
    locationId: {
      type: [String, Number], 
      default: null, 
    },
  },

  data() {
    return {
      paymentSource: this.defaultPaymentSource,
      note: null,
      selectedPaymentMethodId:null,
      paymentSources: paymentSourcesOptions,
    };
  },
  
  created(){
    this.selectedPaymentMethodId =
      this.defaultPaymentSource == "DPA"
        ? this.paymentMethodId
        : null;
  },

  methods: {
    paymentMethodSelected(paymentMethodId){
      this.selectedPaymentMethodId = paymentMethodId;
    }
  }

};
</script>
