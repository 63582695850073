<template>
  <div class="payment-method-component">
    <el-card shadow="none" class="signature-card">
      <div class="d-flex align-items-center">
        <!-- Section gauche : Radio + Détails -->
        <div class="col-md-6 d-flex align-items-center">
          <div class="icon-container mr-3">
            <i class="el-icon-edit"></i>
          </div>
          <div class="details ms-3">
            <a href="#" @click.prevent="openSignatureDetails">
              <h4 class="text-black name">{{ signature.code }}</h4></a
            >
            <p>{{ signature.contract.name }}</p>
            <em
              ><p>
                {{ $t("COMMON.CREATED_AT") }} :
                {{ $timeZoneDateFormat(signature.created_at) }}
              </p></em
            >
          </div>
        </div>

        <!-- Section droite : Statut + Actions -->
        <div class="col-md-6 text-right">
          <div class="actions ms-3">
            <status-badge
              class="mr-5 p-2 rounded-status-tag"
              :status="signature.status"
            />

            <base-button
              v-if="canBeResend"
              class="elite-button btn-black"
              icon
              size="sm"
              @click="resendEmail"
            >
              <span class="btn-inner--text">
                {{ $t("SIGNATURES.RESEND_SIGNATURE_EMAIL") }}
              </span>
            </base-button>

            <base-button
              class="elite-button btn-black"
              icon
              size="sm"
              @click="viewSignature"
            >
              <span class="btn-inner--text">
                {{ $t("SIGNATURES.VIEW_DETAILS") }}
              </span>
            </base-button>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="showAllDetails">
        <signature-view-global :signature="signature" no-headers />
      </div>
    </el-card>
  </div>
</template>

<script>
import { Card, Tag, Radio, Button } from "element-ui";
import StatusBadge from "./StatusBadge.vue";
import SignatureViewGlobal from "../partials/SignatureViewGlobal.vue";
import { QUERY_ACTIONS_VIEW } from "@/constants/common";
import { STATUS_COMPLETED, STATUS_DECLINED } from "@/constants/signatures";
export default {
  layout: "DashboardLayout",

  components: {
    [Tag.name]: Tag,
    [Card.name]: Card,
    [Radio.name]: Radio,
    [Button.name]: Button,
    StatusBadge,
    SignatureViewGlobal,
  },

  props: {
    signature: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showAllDetails: false,
      STATUS_COMPLETED:STATUS_COMPLETED,
      STATUS_DECLINED:STATUS_DECLINED,
    };
  },

  computed: {
    canBeResend() {
      if(this.signature.status == this.STATUS_COMPLETED){
        return false
      }else if(this.signature.status == this.STATUS_DECLINED){
        return false
      }
      return true;
    },
  },

  methods: {
    openSignatureDetails() {
      this.$router.push({
        name: "List Signatures",
        query: { id: this.signature.id, action: QUERY_ACTIONS_VIEW },
      });
    },

    /**
     * Émet un événement pour afficher les détails d'un moyen de paiement
     */
    viewSignature() {
      this.showAllDetails = !this.showAllDetails;
    },

    /**
     * Émet un événement pour supprimer un moyen de paiement
     */
    deleteSignature() {
      this.$emit("deleteSignature", this.signature);
    },
    resendEmail() {
      this.$emit("resendEmail", this.signature);
    },
  },
};
</script>
