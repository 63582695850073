export const PAYMENT_STATUS_DRAFT = "DRAFT";
export const PAYMENT_STATUS_COMPLETED = "COMPLETED";
export const PAYMENT_STATUS_CANCELED = "CANCELED";
export const PAYMENT_STATUS_PENDING = "PENDING";
export const PAYMENT_STATUS_AWAITING_SIGNATURE =
  "AWAITING_DPA_CONTRACT_SIGNATURE";
export const PAYMENT_STATUS_SIGNATURE_DELINED =
  "DPA_CONTRACT_SIGNATURE_DECLINED";
export const PAYMENT_STATUS_HOLDON = "HOLDON";
export const PAYMENT_STATUS_SENT = "SENT";
export const PAYMENT_STATUS_SENT_TO_BANK = "SENT_TO_BANK";

export const DEPOSIT = "IN";
export const WITHDRAW = "OUT";

export const PAYMENT_SOURCE_MANUAL = "MANUAL";
export const PAYMENT_SOURCE_STRIPE = "STRIPE";
export const PAYMENT_SOURCE_PAYPAL = "PAYPAL";
export const PAYMENT_SOURCE_CASH = "CASH";
export const PAYMENT_SOURCE_CHECK = "CHECK";
export const PAYMENT_SOURCE_DPA= "DPA";
export const PAYMENT_SOURCE_UNKNOWN= "UNKNOWN";

export const paymentStatusesOption = {
  [PAYMENT_STATUS_DRAFT]: "DRAFT",
  [PAYMENT_STATUS_COMPLETED]: "COMPLETED",
  [PAYMENT_STATUS_CANCELED]: "CANCELED",
  [PAYMENT_STATUS_PENDING]: "PENDING",
  [PAYMENT_STATUS_AWAITING_SIGNATURE]: "AWAITING_DPA_CONTRACT_SIGNATURE",
  [PAYMENT_STATUS_SIGNATURE_DELINED]: "DPA_CONTRACT_SIGNATURE_DECLINED",
  [PAYMENT_STATUS_HOLDON]: "HOLDON",
  [PAYMENT_STATUS_SENT]: "SENT",
};

export const dpaPaymentsStatuses = {
  [PAYMENT_STATUS_PENDING]: "PENDING",
  [PAYMENT_STATUS_HOLDON]: "HOLDON",
  [PAYMENT_STATUS_SENT]: "SENT",
  [PAYMENT_STATUS_SENT_TO_BANK]: "SENT_TO_BANK",
  [PAYMENT_STATUS_COMPLETED]: "COMPLETED",
};

export const paymentSourcesOption = {
  [PAYMENT_SOURCE_MANUAL]: "MANUAL",
  [PAYMENT_SOURCE_STRIPE]: "STRIPE",
  [PAYMENT_SOURCE_PAYPAL]: "PAYPAL",
  [PAYMENT_SOURCE_CASH]: "CASH",
  [PAYMENT_SOURCE_DPA]: "DPA",
};

export const paymentSourcesOptions = {
  [PAYMENT_SOURCE_CHECK]: "CHECK",
  [PAYMENT_SOURCE_CASH]: "CASH",
  [PAYMENT_SOURCE_DPA]: "DPA",
  [PAYMENT_SOURCE_UNKNOWN]: "UNKNOWN",
};
