<template>
  <el-select
    v-model="paymentMethodModel"
    @change="paymentMethodChanged"
    autocomplete="new-password"
    :placeholder="$t('PAYMENT_METHODS.SELECT')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getList"
    :loading="loading"
    :allow-create="allowCreate"
    class="w-100"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="paymentMethod in paymentMethods"
      :key="paymentMethod.id"
      :value="paymentMethod.id"
      :label="`${paymentMethod.details.account_number}`"
    >
      <template #default>
        <span class="pm-account">{{
          paymentMethod.details.account_number
        }}</span>
        <span class="text-sm custom-tag"  v-if="paymentMethod.is_default">
          <em> {{ $t("COMMON.DEFAULT") }}</em></span>
      </template>
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select, Tag } from "element-ui";
import _ from "lodash";

export default {
  name: "PaymentMethodSelector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tag.name]: Tag,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    allowNone: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    paymentMethod: {
      type: [String, Array],
      default: null,
    },
    filterLocation: {
      type: String,
      default: null,
      description: "Location id",
    },
  },

  data() {
    return {
      sort: "-created_at",
      paymentMethodModel: this.paymentMethod, // Modèle pour la sélection
      paymentMethods: [], // Liste des paymentMethods récupérées
      loading: false, // Indicateur de paymentMethodment
      query: "", // Pour la recherche dynamique
    };
  },

  created() {
    this.getList(null, this.paymentMethod); // Charger les données initiales
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),
    // Fonction pour récupérer les paymentMethods avec support pour tableau d'IDs ou ID unique
    async getList(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: this.sort,
          filter: {
            ...(query ? { search: query } : {}),
            ...(Array.isArray(ids) ? { ids: ids } : ids ? { id: ids } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterLocation) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              holder_id: this.filterLocation,
              holder_type: "Location",
            },
          };
        }

        await this.$store.dispatch("paymentMethods/list", params);
        const paymentMethodsArr = await this.$store.getters[
          "paymentMethods/list"
        ];
        this.paymentMethods = paymentMethodsArr;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    // Emission de l'événement lorsque la sélection change
    paymentMethodChanged(paymentMethod) {
      this.$emit("paymentMethodChanged", paymentMethod);
    },
  },

  watch: {
    paymentMethod(newPaymentMethod) {
      if (newPaymentMethod) {
        this.paymentMethodModel = newPaymentMethod;
        // Si la valeur a changé, on recharge la liste avec l'ID ou les IDs
        if (newPaymentMethod !== this.paymentMethodModel) {
          this.getList(null, newPaymentMethod);
        }
      } else {
        this.paymentMethodModel = null;
      }
    },
    filterLocation: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>
