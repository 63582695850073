<template>
  <div>
    <base-input class="mb-2" :label="$t('PAYMENTS.PAYMENT_SOURCE')">
      <el-radio-group v-model="paymentSource">
        <el-radio
          v-for="(label, val) in paymentSources"
          :key="val"
          name="payment_source"
          :label="val"
          >{{ $t("PAYMENTS." + label) }}</el-radio
        >
      </el-radio-group>
    </base-input>

    <base-input :label="$t('PAYMENTS.PAYMENT_NOTE')">
      <el-input
        class="mb-2"
        type="textarea"
        rows="4"
        v-model="note"
        id="payment-source-note"
        :placeholder="$t('PAYMENTS.PAYMENT_NOTE')"
      />
    </base-input>
  </div>
</template>

<script>
import { RadioGroup, Radio } from "element-ui";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { paymentSourcesOptions } from "@/constants/payments";
export default {
  name: "PaymentValidationForm",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    BaseInput,
  },

  props: {
    defaultPaymentSource: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      paymentSource: this.defaultPaymentSource,
      note: null,
      paymentSources: paymentSourcesOptions,
    };
  },
};
</script>
